import { MenuItem } from "../../../plugins/gatsby-plugin-components/src/menu/menuItem";

export const MenuItemList: MenuItem[] = [{
  content: 'Sign in',
  condition: ({ user }) => {
    return user === undefined
  },
  to: "/sign-in"
}, {
  content: 'App',
  condition: ({ user }) => {
    return user !== undefined
  },
  to: "/app/",
}, {
  content: "Logout",
  to: '/sign-out',
  condition: ({ user }) => {
    return user !== undefined
  },
  onClick: (e, { firebase }) => {
    e.preventDefault();
    firebase.auth().signOut();
  }
}]
