/** @jsx jsx */
import { jsx } from "theme-ui";
import { useContext, useState } from "react";
import { FirebaseContext } from "gatsby-plugin-firebase"
import { app } from "firebase";
import { navigate } from "@reach/router";
import { Heading, Box, Label, Input, Button, Message, Container, Grid } from '@theme-ui/components'

interface SignInErr {
  code: string
  message: string
}

export const Login = () => {
  const firebase = useContext(FirebaseContext) as app.App;
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState<SignInErr | undefined>(undefined)

  return <form onSubmit={(e) => {
    e.preventDefault();
    if (!firebase) {
      return
    } else {

      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(() => navigate("/app/"))
        .catch((err: any) => setError(err))
    }
    console.log(email, password)
  }}>
    <Box my={3}>
      <Label>
        Email
      </Label>
      <Input type="email" value={email} onChange={(e: any) => setEmail(e.target!.value)} name="email" />
    </Box>
    <Box mb={3}>
      <Label>
        Password
      </Label>
      <Input type="password" value={password} onChange={(e: any) => setPassword(e.target!.value)} name="password" />
    </Box>
    <Box mb={3}>
      <Button type="submit">Sign in</Button>
    </Box>
  </form>
}