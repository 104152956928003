/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link, Heading } from '@theme-ui/components';
import { Link as RouteLink } from "gatsby"
import { FC } from "react";

export const HeaderTitle: FC = ({ children }) => {
  return (
    <Heading
      as="div"
      variant="nav"
      sx={{ margin: 0 }}
    >
      <Link
        as={RouteLink}
        to="/"
        sx={{
          color: 'text',
          textDecoration: `none`,
        }}
      >
        {children}
      </Link>
    </Heading>
  )
}
