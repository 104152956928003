/** @jsx jsx */
import { jsx } from "theme-ui";
import { Flex } from '@theme-ui/components';
import { FC } from "react";

export const HeaderMenu: FC = ({ children }) => {
  return <Flex as="ul" sx={{
    listStyle: 'none',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flex: '1'
  }}>
    {children}
  </Flex>
}
