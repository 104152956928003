import React, { useState } from "react"
import { App } from './app'
import { Login } from "./login"
import Modal from "./modal"

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }: any) => {

  return (
    <App>
      {element}
      <Modal open={false}>
        <Login />
      </Modal>
    </App>
  )
}