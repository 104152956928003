/** @jsx jsx */
import { jsx } from "theme-ui";
import { FC } from "react";
import { Container, Flex } from '@theme-ui/components';

export const HeaderLayout: FC = ({ children }) => {
  return <Container>
    <Flex sx={{
      alignItems: 'center',
    }}>
      {children}
    </Flex>
  </Container>
}