/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import { FC, useEffect } from 'react'

export const Modal: FC<any> = ({ children, close, open }) => {
  useEffect(() => {
    if (open) {
      (document.body as any).style = "position: absolute; overflow:hidden;";
    } else {
      (document.body as any).style = ""
    }
    return () => {
      (document.body as any).style = ""
    }
  }, [open])

  return (
    open ? <Box
      sx={{
        position: "fixed",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}
    >
      <Box sx={{
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.4)',
        backdropFilter: "blur(10px)",
        zIndex: -1
      }} onClick={close} />
      <Box sx={{
        backgroundColor: "primary",
        p: 3,
        zIndex: 100,
      }}>
        {children}
      </Box>
    </Box>
      : null)
}

export default Modal
