const heading = {
  fontFamily: 'heading',
  lineHeight: 'heading',
  fontWeight: 'heading',
}

const body = {
  fontFamily: 'body',
  lineHeight: 'body',
  fontWeight: 'body',
}

export default {
  breakpoints: ['40em', '52em', '64em'],
  sizes: {
    container: ['1024px'],
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: '"Poppins", sans-serif',
    heading: '"Poppins", sans-serif',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  fontWeights: {
    body: 300,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  colors: {
    text: '#232F34',
    background: '#ffffff',
    primary: '#ffd830',
    secondary: '#b030ff',
    highlight: 'rgba(255,216,48, 0.3)',
    muted: '#f6f6fa',
    yellow: [
      '#ffd830',
      '#ffd830',
      "#E6BF17",
      "#CCA500"
    ],
    purple: [
      "rgba(21,41,124, 0.3)",
      "rgb(21,41,124)",
      "#000030",
      "rgb(14,28,67)"
    ]
  },
  buttons: {
    primary: {
      ...body,
      fontWeight: 'bold',
      color: 'white',
      bg: 'purple.1',
      cursor: 'pointer',
      '&:hover': {

      },
    },
  },
  text: {
    nav: {
      fontSize: [3, 5],
      color: 'text',
      textDecoration: `none`,
    },
    jumbo: {
      fontSize: 4,
    },
    title: {
      fontSize: 2,
      color: 'text',
      fontWeight: 'bold',
    }
  },
  messages: {

  },
  cards: {
    primary: {
      position: 'relative'
    }
  },
  badges: {
    primary: {
      color: 'background',
      bg: 'purple.1',
      borderRadius: '999px',
      py: 1,
      px: 3,
      fontWeight: 100
    },
  },
  links: {
    nav: {
      ...body,
      fontSize: [1, 2],
      color: 'text',
      textDecoration: 'none',
      fontWeight: 'bold',
      lineHeight: '1em',
      margin: 0,
      p: 0
    },
    menu: {
      ...body,
      color: 'text',
      textDecoration: 'none',
      fontWeight: 'bold',
      p: 3,
      "&.active": {
        backgroundColor: 'primary'
      }
    }
  },
  layout: {
    container: {
      paddingX: [3, 4]
    },
    narrow: {
      paddingX: [0, 4]
    }
  },
  forms: {
    textarea: {
      ...body
    },
    checkbox: {
      color: 'purple.1',
      'input ~ &': {
        margin: 0,
      },
      'input:checked ~ &': {
        color: 'purple.1',
      },
      'input:focus ~ &': {
        color: 'purple.1',
        bg: 'purple.0',
      },
    }
  },
  styles: {
    root: {
      color: 'text',
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      ...heading,
      fontSize: 5,
    },
    h2: {
      ...heading,
      fontSize: 4,
    },
    h3: {
      ...heading,
      fontSize: 3,
    },
    h4: {
      ...heading,
      fontSize: 2,
    },
    h5: {
      ...heading,
      fontSize: 1,
    },
    h6: {
      ...heading,
      fontSize: 0,
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    a: {
      pb: 1,
      color: 'text',
      textDecoration: 'none',
      fontWeight: 'bold',
      "&.active": {
        borderBottom: '2px solid',
        borderColor: 'secondary'
      }
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
  },
}