import React, { useContext, Fragment } from "react";
import { FirebaseContext } from "gatsby-plugin-firebase"
import { UserContext } from "../../../gatsby-firebase-authentication";
import { app } from "firebase";
import { MenuItemList } from "./headerMenuItemList";
import { Box, Link } from '@theme-ui/components'
import { Link as RouteLink } from "gatsby"

const nop = () => { }

export const HeaderMenuItems = () => {
  const user = useContext(UserContext) as any;
  const firebase = useContext(FirebaseContext) as app.App


  return (
    <>
      {MenuItemList.map(i => {
        if (i.condition && i.condition({ user })) {
          return (
            <Box key={i.to} pr={3}>
              <Link
                variant="nav"
                as={RouteLink}
                onClick={i.onClick || nop}
                to={i.to}>
                {i.content}
              </Link>
            </Box>
          )
        } else {
          return undefined
        }
      })}
    </>
  )
}