/** @jsx jsx */
import { jsx } from "theme-ui";
import { Link, Box } from '@theme-ui/components';
import { useContext, Fragment } from "react";
import { FirebaseContext } from "gatsby-plugin-firebase"
import { UserContext } from "../../../gatsby-firebase-authentication";
import { app } from "firebase";
import { HeaderLayout } from "./headerLayout";
import { HeaderTitle } from "./headerTitle";
import { HeaderMenu } from "./headerMenu";
import { HeaderMenuItems } from "./headerMenuItems";

export const Header = ({ siteTitle }: { siteTitle: string }) => {

  return (
    <Box sx={{
      minWidth: '100vw',
      height: ['70px', '90px'],
      display: 'flex',
      alignItems: 'center',
      backgroundColor: 'primary',
      flex: '0 0 auto'
    }}>
      <HeaderLayout>
        <HeaderTitle>{siteTitle}</HeaderTitle>
        <HeaderMenu>
          <HeaderMenuItems />
        </HeaderMenu>
      </HeaderLayout>
    </Box >
  )
}
