import React, { useState, FC } from "react"

import { useFirebase } from "gatsby-plugin-firebase"
import { app, User } from 'firebase';
import { navigate } from 'gatsby'
import { UserContext } from './user-context';

export const App: FC = ({ children }) => {
  const [user, setAuthd] = useState<User | undefined>(undefined)

  useFirebase((fb: app.App) => {
    fb.auth()
      .onAuthStateChanged((u) => {
        if (u) {
          setAuthd(u)
        } else {
          setAuthd(undefined)
          navigate("/")
        }
      })
  }, [])

  return (
    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  )
}