/** @jsx jsx */
import { jsx } from "theme-ui";
import { Text, Box, Container } from '@theme-ui/components'

export const Footer = () => (
  <Box sx={{ minWidth: '100vw', backgroundColor: 'purple.2', color: 'background' }}>
    <Container>
      <Box py={4}>
        <Text sx={{ textAlign: 'center', fontSize: 0 }}>
          Copyright © 2019, @samjohnduke
        </Text>
      </Box>
    </Container>
  </Box>
)

export default Footer
