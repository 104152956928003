/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import { FC } from 'react';
import { Box, Flex } from '@theme-ui/components'
import "./reset.css"
import { Header } from "./layout/header";
import Footer from "./layout/footer";

export const Layout: FC<any> = ({ element }) => {
  return <Box sx={{
    height: '100%'
  }}>
    <Styled.root sx={{
      height: '100%'
    }}>
      <Flex sx={{
        height: '100%',
        flexDirection: 'column'
      }}>
        <Header siteTitle="Wallpapers" />
        <main sx={{ flex: 1 }}>
          {element}
        </main>
        <Box>
          <Footer />
        </Box>
      </Flex>
    </Styled.root>
  </Box>
}

export default Layout